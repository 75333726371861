/*  regex to check if a string is an isoDate
 */
export const isoDatePattern: RegExp = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/

/**
 * Regex to check if a string has multiple whitespaces.
 * @remarks Not global (will stop after first match).
 * @remarks Multiline pattern.
 */
export const multipleWhiteSpacePattern: RegExp = /\s{2,}/m

/**
 * Regex to check if a string has any lowercase characters.
 * @remarks Not global (will stop after first match).
 * @remarks Multiline pattern.
 */
export const hasLowerCasePattern: RegExp = /[a-z]/m

/**
 * Regex to check if a string is a valid IBAN number.
 * @remarks Not global (will stop after first match).
 * @remarks Not multiline pattern.
 * @remarks Not case-insensitive pattern.
 * @remarks Will work also with non-swiss IBANs.
 */
export const ibanPattern: RegExp = /\b[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1,2})?\b/
