import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts'
import { emitter } from '@/main'
import { jwtDecode } from 'jwt-decode'
import { personsService } from '@/services/persons'
import { showError } from '@/services/axios'

type BearerStatus = 'VALID' | 'INVALID_EXPIRED' | 'INVALID_NOT_SET'

const oidc_keycloak_dev = {
  authority: 'http://localhost:8198/auth/realms/dbm',
  clientId: 'dbm-webapp',
  clientRoot: 'http://localhost:8080',
  scope: 'openid profile',
  post_logout_redirect_uri: 'http://localhost:8080'
}
const oidc_keycloak_prod = {
  authority: 'https://' + location.hostname + '/auth/realms/dbm',
  clientId: 'dbm-webapp',
  clientRoot: 'https://' + location.hostname,
  scope: 'openid profile',
  post_logout_redirect_uri: 'https://' + location.hostname
}
const oidc_agate_integration = {
  authority: 'https://idp-rf.agate.ch/auth/realms/agate',
  clientId: 'milchdb-rf',
  clientRoot: 'https://' + location.hostname,
  scope: 'openid loginid',
  post_logout_redirect_uri: 'https://rf.agate.ch/'
}
const oidc_agate_prod = {
  authority: 'https://idp.agate.ch/auth/realms/agate',
  clientId: 'milchdb',
  clientRoot: 'https://' + location.hostname,
  scope: 'openid loginid',
  post_logout_redirect_uri: 'https://agate.ch/'
}

function getOIDC_config(): any {
  if (process.env.NODE_ENV === 'development') return oidc_keycloak_dev
  switch (window.location.hostname.split('.')[0]) {
    case 'prod':
      return oidc_agate_prod
    case 'integration':
      return oidc_agate_integration
    case 'test':
      return oidc_keycloak_prod
  }
  return null
}

export function getOidcSettings(): UserManagerSettings {
  const environment = getOIDC_config()
  return {
    authority: environment.authority,
    client_id: environment.clientId,
    redirect_uri: `${environment.clientRoot}/oidc-callback`,
    silent_redirect_uri: `${environment.clientRoot}/oidc-callback`,
    post_logout_redirect_uri: environment.post_logout_redirect_uri,
    response_type: 'code',
    scope: environment.scope,
    userStore: new WebStorageStateStore({ store: window.localStorage })
  }
}

class AuthService {
  private static _instance: AuthService

  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  private userManager: UserManager = new UserManager(getOidcSettings())
  public loadedUser: User | null = null

  constructor() {
    //https://authts.github.io/oidc-client-ts/classes/UserManagerEvents.html
    this.userManager.events.addUserLoaded(() => {
      this.userManager
        .getUser()
        .then((user) => {
          this.loadedUser = user
          console.log('emit oidcUserLoaded')
          personsService.oidcUserLoaded()
          emitter.emit('oidcUserLoaded', user)
        })
        .catch((err) => {
          console.error('emit oidcUserLoaded error', err)
        })
    })
    this.userManager.events.addUserUnloaded(() => {
      console.log('emit UserUnloaded')
      this.loadedUser = null
      emitter.emit('oidcUserUnloaded')
    })
    this.userManager.events.addSilentRenewError(() => {
      console.error('emit SilentRenewError')
      this.userManager.signinRedirect().catch((err) => {
        console.error('emit SilentRenewError', err)
      })
      //emitter.emit('oidcSilentRenewError')
    })
  }

  public async getUser(): Promise<User | null> {
    const user = await this.userManager.getUser().catch((err) => {
      console.error('getUser error', err)
      return null
    })
    this.loadedUser = user
    return user
  }

  public login(): Promise<User | void> {
    //https://authts.github.io/oidc-client-ts/classes/UserManager.html#signinPopup
    return this.userManager.signinRedirect().catch((err) => {
      console.error('login error', err)
    })
  }

  public renewToken() {
    this.userManager.signinSilent().catch((err) => {
      console.error('renewToken error1', err)
      this.userManager.signinRedirect().catch((err) => {
        console.log('renewToken error2', err)
        showError({ message: 'IDP unavailable. pls login first' })
      })
    })
  }

  public signinCallback(url: string): Promise<User | void> {
    return this.userManager.signinCallback(url).catch((err) => {
      console.error('signinCallback error', err)
    })
  }

  public signinSilent(): Promise<User | null> {
    return this.userManager.signinSilent()
  }

  public signinRedirect(): Promise<void> {
    return this.userManager.signinRedirect().catch((err) => {
      console.error('signinRedirect error', err)
    })
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect().catch((err) => {
      console.error('logout error', err)
    })
  }
  public getBearerStatus(): BearerStatus {
    if (!this.loadedUser?.access_token) return 'INVALID_NOT_SET'
    const decoded = jwtDecode(this.loadedUser.access_token) // sec
    const now = new Date().getTime() // ms
    if (!decoded.exp) {
      return 'INVALID_NOT_SET'
    }
    if (decoded.exp * 1000 < now) {
      return 'INVALID_EXPIRED'
    }
    return 'VALID'
  }
}
export const authService = AuthService.Instance

// refresh the token when Browsertab regains focus
addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    if (authService.getBearerStatus() != 'VALID') {
      console.log('got a visibilityChange event')
      authService.renewToken()
    } else {
      console.log('got a visibilityChange event but token is valid')
    }
  }
})
