import { CompileError, MessageCompiler, MessageContext } from 'vue-i18n'
import { isString, isValidISODate, isValidString } from '@/utils/TypeGuards'
import { formatDate } from '@/utils/TimeUtils'

/**
 * Replaces Java-style placeholders ($1,$2) in the message with values from the context.
 */
export function replaceJavaPlaceholders(message: string, values: MessageContext['list']): string {
  return message.replace(/\$\d+/g, (placeholder: string) => {
    const index = Number(placeholder.slice(1)) - 1
    const value: unknown = values(index)

    if (isValidISODate(value)) {
      return formatDate(new Date(value))
    }

    // try to resolve by values otherwise don't replace
    const resolved = value !== undefined ? value : placeholder
    // to string
    return String(resolved)
  })
}

/**
 * This function is called by vue-i18n to compile a message.
 */
export const messageCompiler: MessageCompiler = (message, ctx) => {
  if (isString(message)) {
    //messages can be cleared by the TSM-Admin (message ="")
    return (ctx: MessageContext) => {
      if (message.indexOf('$') > -1 && ctx.list.length > 0) {
        // is probably a Java-style placeholder
        return replaceJavaPlaceholders(message, ctx.list)
      }
      return message
    }
  } else {
    console.warn('messageCompiler: ResourceNode Translation not implemented: message is not a string', typeof message, message, ctx)
    if (ctx.onError) {
      ctx.onError(new Error('ResourceNode Translation not implemented: message is not a string') as CompileError)
    }
    return () => ctx.key
  }
}
