//const availableLogLevels = ['debug', 'error', 'info', 'log', 'warn', 'dir', 'dirxml', 'table', 'trace', 'group', 'groupCollapsed', 'groupEnd', 'clear', 'count', 'countReset', 'assert', 'profile', 'profileEnd', 'time', 'timeLog', 'timeEnd', 'timeStamp', 'context', 'createTask', 'memory'] as const;
import { isDev } from '@/utils/environment'

const availableLogLevels = ['debug', 'error', 'info', 'log', 'warn'] as const

const globalLoggerOptions: LoggerOptions = {
  blockedNames: [],
  // allow in development all levels, but elsewhere only error and info
  level: isDev ? [...availableLogLevels] : ['error']
}

class Logger {
  constructor(
    private readonly name: LoggerName,
    private readonly options: LoggerOptions = globalLoggerOptions
  ) {
    // add a colon to the name e.g. `WhateverService: Any message
    this.name += ':'
  }

  private stdout(level: LogLevel, ...args: unknown[]) {
    if (this.options.level.includes(level) && !this.options.blockedNames.includes(this.name)) {
      console[level](this.name, ...args)
    }
  }

  debug(...args: unknown[]): void {
    this.stdout('debug', ...args)
  }

  error(...args: unknown[]): void {
    this.stdout('error', ...args)
  }

  /**
   * Log a message as error and get the error back.
   *
   * @remarks Useful to avoid defining an error, logging it and throwing it afterward.
   * @example
   * throw logger.errorOnly('Something went wrong')
   * // instead of
   * const error = new Error('Something went wrong')
   * logger.error(error)
   * throw error
   */
  asError(errorMessage: string, ...args: unknown[]): Error {
    const error = new Error(errorMessage)
    // log error
    this.error(error, ...args)
    return error
  }

  info(...args: unknown[]): void {
    this.stdout('info', ...args)
  }

  log(...args: unknown[]): void {
    this.stdout('log', ...args)
  }

  warn(...args: unknown[]): void {
    this.stdout('warn', ...args)
  }

  /** Will redirect to error log and send an anomaly report */
  anomaly(...args: unknown[]): void {
    // todo: anomaly service. send anomaly report instead of logging an error nobody will see
    this.error('Anomaly detected. Please report this to the developers.')
    this.error(...args)
  }
}

// don't allow to construct a logger from outside, so we only export the type
export type { Logger }

type LogLevel = (typeof availableLogLevels)[number]

/** Prefix of logger */
type LoggerName = string

export interface LoggerOptions {
  /** Blocked names, those loggers will not log anything */
  blockedNames: readonly string[]

  /** Allow log levels (e.g. log, warn, error etc.) */
  level: readonly LogLevel[]
}

const loggerCache: Map<LoggerName, Logger> = new Map<LoggerName, Logger>()

/** Get a {@link Logger} instance */
export function getLogger(loggerName: LoggerName, loggerOptions?: LoggerOptions): Logger {
  if (loggerCache.has(loggerName)) {
    return loggerCache.get(loggerName) as Logger
  }
  const newLogger = new Logger(loggerName, loggerOptions)
  loggerCache.set(loggerName, newLogger)
  return newLogger
}
