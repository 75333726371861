<template>
  <v-menu v-model="openMenu">
    <template #activator="{ props }">
      <v-text-field
        v-bind="props"
        ref="datepickerfield"
        v-model="localChDate"
        :label="dateLabel"
        prepend-inner-icon="mdi-calendar"
        :rules="calcRules()"
        :readonly="readonly"
        :clearable="clearable"
        clear-icon="mdi-close-circle"
        density="compact"
        min-width="170"
        @click:clear="clearMessage"
        @change="change"
        @update="update"
        @keyup.enter="keyupEnter"
        @keyup.esc="keyupEsc"
      />
    </template>
    <vue-date-picker
      ref="datepicker"
      v-model="pickerDate"
      inline
      :locale="$route.params.langKey"
      :format="dateFormatted"
      :cancel-text="t('cancel')"
      :select-text="t('choose')"
      :readonly="readonly"
      :clearable="clearable"
      date-picker
      :enable-time-picker="false"
      :class="classProp"
    >
    </vue-date-picker>
  </v-menu>
</template>

<script setup lang="ts">
import { ref, computed, watch, useTemplateRef } from 'vue'
import { format, parseISO } from 'date-fns'
import { $rules } from '@/plugins/rules'
import { VTextField } from 'vuetify/components/VTextField'
import { useI18n } from 'vue-i18n'
const t = useI18n().t

const props = defineProps({
  modelValue: { type: [String, null], default: null },
  dateLabel: { type: String, default: '' },
  clearable: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  class: { type: String, default: '' }
})
const openMenu = ref(false)
const emit = defineEmits(['update:modelValue', 'change', 'keyup', 'keyup.enter'])

const localChDate = ref(formatDate(props.modelValue))
const datepickerfield = useTemplateRef('datepickerfield')

const pickerDate = computed({
  get: () => {
    if (props.modelValue) {
      return parseISO(props.modelValue)
    } else {
      return new Date()
    }
  },
  set: (value) => {
    emit('update:modelValue', format(value, 'yyyy-MM-dd'))
    emit('change')
    openMenu.value = false
    localChDate.value = format(value, 'dd.MM.yyyy')
  }
})

const dateFormatted = computed(() => formatDate(props.modelValue))

watch(
  () => props.modelValue,
  (value) => {
    localChDate.value = value ? formatDate(value) : null
  }
)

function dateUpdate(date: Date) {
  // this fn is called by the picker
  emit('update:modelValue', format(date, 'yyyy-MM-dd'))
  emit('change')
  localChDate.value = format(date, 'dd.MM.yyyy')
}

function keyupEnter() {
  emit('keyup.enter')
}

function keyupEsc() {
  if (props.modelValue && props.modelValue.length === 10) {
    localChDate.value = format(props.modelValue, 'dd.MM.yyyy')
  } else {
    localChDate.value = ''
  }
}

function change() {
  if (datepickerfield.value) {
    datepickerfield.value.validate()
    if (datepickerfield.value.isValid === true) {
      emit('update:modelValue', parseDate(localChDate.value))
      emit('change')
    }
  }
}

function update() {
  if (datepickerfield.value) {
    datepickerfield.value.validate()
  }
}

function clearMessage() {
  emit('update:modelValue', null)
  emit('change')
}

function formatDate(date: string | undefined | null) {
  if (!date || date.length < 7) {
    return null
  }
  const [year, month, dayTime] = date.split('-')
  const [day] = dayTime.split('T')
  return `${day}.${month}.${year}`
}

function parseDate(date: string | undefined | null) {
  if (!date || date.length <= 7) return null
  const [day, month, year] = date.split('.')
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}

function calcRules() {
  if (props.required) {
    return [$rules.isCHDate, $rules.required]
  } else {
    return [$rules.isCHDate]
  }
}

function altPos(element: HTMLElement) {
  //otherwise a picker within a dialog will fail in positioning e.g. rolepicker http://localhost:8080/de/applicationUsers/3
  let bodyRect = document.body.getBoundingClientRect()
  let elemRect = element.getBoundingClientRect()
  return { top: elemRect.bottom - bodyRect.top, left: elemRect.left - bodyRect.left }
}

// To handle class passed to the component
const classProp = computed(() => props.class)
</script>
