/* Global Constants */

export const legacyUrl: string = ['prod.dbmilch.ch', 'prod.bdlait.ch'].includes(location.hostname) ? 'https://legacy.dbmilch.ch/' : 'https://test.dbmilch.ch/'

/**
 * @Deprecated Let the client automatically handle protocol and hostname
 */
export const protocol: string = process.env.NODE_ENV === 'development' ? 'http://' : 'https://'

/**
 * @Deprecated Let the client automatically handle protocol and hostname
 */
export const apiURL: string = process.env.NODE_ENV === 'development' ? '/api' : 'https://' + location.hostname + '/api'

export const isDev: boolean = process.env.NODE_ENV === 'development'

export const itemsPerPageOptions: number[] = [10, 20, 50, 100] as const

export const Languages: string[] = ['de', 'fr', 'it'] as const

/**
 * If true, the pinia stores will be persisted in the local storage.
 * @remarks Each store individually can be persisted or not by setting the `persist` property in the store.
 */
export const persistPiniaStores: boolean = true

/**
 * The maximum age of the cache in milliseconds.
 * @remarks Each store can have its own (or multiple) cache times.
 */
export const cacheMaxAge: number = 1000 * 30 // 30 seconds
