<template>
  <div v-if="privileges" class="d-print-none">
    <v-container id="topnav_person" class="container-xl container keepInside">
      <div id="logo" class="header-bar-left">
        <v-img
          v-if="selectedLang.lang === 'fr' || selectedLang.lang === 'it'"
          src="../assets/images/bdlaitch_rgb.svg"
          alt="dbmilch"
          class="logo-size"
          height="48"
          width="200"
        />
        <img v-else src="../assets/images/dbmilchch_rgb.svg" alt="dbmilch" class="logo-size" height="48" width="200" />
      </div>
      <div id="profile" class="header-bar-right margin-left center-vertically">
        <v-menu offset-y>
          <template #activator="{ props }">
            <span v-bind="props" id="profileMenu" class="pointer">
              <v-icon dbmpassivgrey>mdi-dots-horizontal</v-icon>
            </span>
          </template>
          <v-list>
            <v-list-subheader v-if="isAuthenticated">
              {{ userName }}
            </v-list-subheader>
            <v-list-subheader v-else> ... </v-list-subheader>
            <v-list-item>
              <v-list-item-title class="dropdown-item custom-dropdown-item-title">
                <span v-html="t('system_menu_user_account')" />
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="isAuthenticated"
              class="text-decoration-none"
              :to="{
                name: 'profile',
                params: { lang: selectedLang.lang, tab: 'user' }
              }"
            >
              <v-list-subheader id="menuProfile">
                <span v-html="t('system_menu_profile')" />
              </v-list-subheader>
            </v-list-item>
            <v-list-item>
              <v-list-subheader v-if="isAuthenticated" id="menuLogout" class="pointer" @click.prevent="signOut()">
                <span v-html="t('system_menu_logout')" />
              </v-list-subheader>
              <v-list-subheader v-else id="menuLogin" @click.prevent="login()">
                <span v-html="t('system_menu_login')" />
              </v-list-subheader>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="dropdown-item custom-dropdown-item-title">
                <span v-html="t('system_menu_service')" />
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-subheader id="menuHelpContact" class="pointer" @click.prevent="gotoExternalUrl($t('url_help_contact'))">
                <span v-html="t('system_menu_help_contact')" />
              </v-list-subheader>
            </v-list-item>
            <v-list-item>
              <v-list-subheader id="menuInstructions" class="pointer" @click.prevent="gotoExternalUrl($t('url_instructions'))">
                <span v-html="t('system_menu_instructions')" />
              </v-list-subheader>
            </v-list-item>
            <v-list-item>
              <!-- webpack will inject a timestamp check <html lang="en" data-build-timestamp-utc="<%= new Date().toISOString() %>"> in index.html  -->
              <v-list-subheader id="version"> Build {{ builded }} </v-list-subheader>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div id="language" class="header-bar-right margin-left mt-2">
        <LanguageSelector />
      </div>
      <div v-if="privileges.has({ path: '/masterdata', permission: 'read' })" id="search" class="header-bar-right center-vertically">
        <a class="link-right pointer text-decoration-none" @click.prevent="search()">
          <v-icon right>mdi-magnify</v-icon>
          <span v-html="t('search')"
        /></a>
      </div>
      <div v-if="isAuthenticated" id="logout" class="header-bar-right margin-left center-vertically">
        <a class="link-right pointer text-decoration-none" @click.prevent="signOut()" v-html="t('logout')" />
      </div>
      <div v-else id="login" class="header-bar-right margin-left center-vertically">
        <a class="link-right pointer" @click.prevent="login()" v-html="t('login')" />
      </div>
      <v-dialog v-model="loginBlocker" persistent width="800px">
        <v-card>
          <v-card-title>
            <span v-html="t('system_pls_login_dialog_title')" />
          </v-card-title>
          <v-card-text>
            <span v-html="t('system_pls_login_dialog_text')" />
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click.prevent="login()">
              <span v-html="t('system_pls_login_dialog_action_login')" />
            </v-btn>
            <v-btn color="secondary" :to="{ name: 'home', params: { lang: selectedLang.lang } }">
              <span v-html="t('system_pls_login_dialog_action_home')" />
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <DomainSelector />
      <div v-if="privileges.has({ path: '/messages/outbox', permission: 'read' })" id="messages" class="header-bar-right emailTopMargin">
        <v-btn icon="mdi-email-outline" class="noborder" :to="{ name: 'messages_outbox_read', params: { lang: selectedLang.lang } }"> </v-btn>
      </div>
    </v-container>

    <div class="navigation-borders">
      <div class="container-xl container pb-0 pt-0">
        <nav class="navbar navbar-expand-lg navbar-light">
          <router-link v-if="ready" class="menu-padding navbar-brand home-color home-size" :to="{ name: 'home', params: { lang: selectedLang.lang } }">
            HOME
          </router-link>
          <v-progress-linear v-if="privilegesStore.loading > 0" indeterminate />
          <v-btn
            class="navbar-toggler"
            aria-controls="navbarNavLightDropdown"
            :aria-expanded="isNavbarOpen.toString()"
            aria-label="Toggle navigation"
            @click="toggleNavbar"
          >
            <v-icon dbmpassivgrey> mdi-menu </v-icon>
          </v-btn>
          <div id="navbarNavLightDropdown" :class="['collapse navbar-collapse', { show: isNavbarOpen }]">
            <ul class="navbar-nav">
              <li
                v-for="item in navTreeData"
                v-show="item.visibility"
                :key="item.label[selectedLang.lang]"
                :class="item.selectedPath ? 'level0 nav-item dropdown menu-font selectedPath' : 'level0 nav-item dropdown menu-font'"
              >
                <a
                  :id="'level0_' + item.label[selectedLang.lang]"
                  class="nav-link menu-padding home-color"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ upper(item, selectedLang.lang) }}
                </a>
                <ul
                  :id="'Flyout_' + item.label[selectedLang.lang]"
                  class="dropdown-menu dropdown-menu-light"
                  :aria-labelledby="'level0_' + item.label[selectedLang.lang]"
                >
                  <div class="container-xl container pb-0 pt-0 left-margin-and-padding-less">
                    <li v-for="(child, indx) in item.children" v-show="child.visibility" :key="'child1_' + indx" class="menu-column">
                      <a
                        :id="'level1_' + child.label[selectedLang.lang]"
                        :class="child.selectedPath ? 'dropdown-item custom-dropdown-item-title selectedPath' : 'dropdown-item custom-dropdown-item-title'"
                        href="#"
                        role="button"
                        >{{ child.label[selectedLang.lang] }}</a
                      >

                      <ul class="dropdown-entry dropdown-entry-hover" :aria-labelledby="'level1_' + item.label[selectedLang.lang]">
                        <li v-for="(child2, indx2) in child.children" v-show="child2.visibility" :key="'child2_' + indx2">
                          <template v-if="!child2.hideNavigation">
                            <a v-if="child2.legacy" @click="gotoLegacyNav(child2.route)">{{ child2.label[selectedLang.lang] }}</a>
                            <router-link v-else :class="child2.selectedPath ? 'selectedPath' : ''" :to="languagePrefix + child2.route">
                              {{ child2.label[selectedLang.lang] }}
                            </router-link>
                          </template>
                        </li>
                      </ul>
                    </li>
                  </div>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { navTree, NavTreeElement } from '@/router/navTree'
import LanguageSelector from '@/components/languageSelector.vue'
import { privileges } from '@/services/privileges'
import { Persons } from '@/services/persons'
import { emitter } from '@/main'
import { authService } from '@/services/auth.service'
import { useLegalEntityStore } from '@/store/LegalEntityStore'
import { format } from 'date-fns'
import DomainSelector from '@/views/DomainSelector.vue'

import 'bootstrap/js/dist/dropdown'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { gotoLegacy } from '@/utils/legacyUtils'
import { getBeta, legacyEnabled } from '@/plugins/globals'
import { Lang, selectedLang } from '@/services/language'
import { usePrivilegesStore } from '@/store/privileges'

const router = useRouter(),
  route = useRoute(),
  t = useI18n().t

const navTreeData = ref(navTree as NavTreeElement[])
const personsService = Persons.Instance
const ready = ref(false)
const isNavbarOpen = ref(false)

const isAuthenticated = computed(() => typeof authService == 'object')
const builded = computed(() => format(document.documentElement.dataset.buildTimestampUtc ?? '', 'dd.MM.yyyy HH:mm'))
const loginBlocker = computed(() => !isAuthenticated.value)

const languagePrefix = computed(() => '/' + route.params.lang)
const userName = computed(() => {
  if (authService.loadedUser?.profile?.family_name) {
    return authService.loadedUser.profile.family_name
  }
  if (authService.loadedUser?.profile?.given_name) {
    return authService.loadedUser.profile.given_name
  }
  if (authService.loadedUser?.profile?.email) {
    return authService.loadedUser.profile.email
  }
  return ' - '
})

function routePushHome() {
  console.log('routePushHome')
  router.push({ name: 'home', params: route.params }).catch(() => {})
}

onMounted(() => {
  emitter.on('initialized', () => {
    ready.value = true
  })
  emitter.on('reloadNav', reloadNav)
  emitter.on('privileges_set', reloadNav)
  emitter.on('privileges_fetched', reloadNav)
  emitter.on('oidcUserUnloaded', oidcUserError)
  emitter.on('oidcSilentRenewError', oidcUserError)
  emitter.on('backendUnAuthorized', oidcUserError)
  emitter.on('routePushHome', routePushHome)
  reloadNav()
})

onUnmounted(() => {
  emitter.off('routePushHome', routePushHome)

  emitter.off('reloadNav', reloadNav)
  emitter.off('privileges_set', reloadNav)
  emitter.off('privileges_fetched', reloadNav)
  emitter.off('oidcUserUnloaded', oidcUserError)
  emitter.off('oidcSilentRenewError', oidcUserError)
  emitter.off('backendUnAuthorized', oidcUserError)
})

const cleanup = () => {
  personsService.reset()
  const somePiniaStore = useLegalEntityStore()
  somePiniaStore.$reset_all()
  reloadNav()
}

const signOut = () => {
  cleanup()
  window.setTimeout(() => {
    authService.logout()
  }, 250)
}

const oidcUserError = () => {
  console.log('oidcUserError')
  cleanup()
  window.setTimeout(() => {
    authService
      .login()
      .then(() => {
        console.log('oidcinterceptor igninRedirect success')
      })
      .catch(() => {
        emitter.emit('toastError', { message: t('idp_unavailable') })
      })
  }, 250)
}

function upper(item: NavTreeElement, selectedLang: Lang): string {
  try {
    return item.label[selectedLang].toUpperCase()
  } catch (e) {
    console.error(e, item, selectedLang)
    return ''
  }
}

const gotoExternalUrl = (url) => {
  window.open(t(url), '_blank')
}

const toggleNavbar = () => {
  isNavbarOpen.value = !isNavbarOpen.value
}

const search = () => {
  let routeName = 'masterdata_search'
  if (privileges.has({ path: '/masterdata/find', permission: 'read' })) {
    routeName = 'masterdata_find'
  }
  router
    .push({
      name: routeName,
      params: route.params
    })
    .catch(() => {})
}
const setNavigationVisibility = (arrayOfRouteElements: NavTreeElement[]) => {
  let somethingVisible = false
  arrayOfRouteElements.forEach((element) => {
    if (element.children && element.children.length > 0) {
      element.visibility = !element.hideNavigation && setNavigationVisibility(element.children)
    } else {
      if (element.visibilityBeta === true && !getBeta()) {
        element.visibility = false
        return false
      }
      element.legacy = element.route.startsWith('/legacy')
      if (element.legacy && !legacyEnabled()) {
        element.visibility = false
        return false
      }
      if (element.route.endsWith('/search')) {
        const testpath = element.route.slice(0, -'/search'.length)
        element.visibility =
          !element.hideNavigation &&
          privileges.has({
            path: testpath,
            permission: 'search'
          })
      } else if (element.route.endsWith('/read')) {
        const testpath = element.route.slice(0, -'/read'.length)
        element.visibility =
          !element.hideNavigation &&
          privileges.hasOneOf([
            {
              path: testpath,
              permission: 'read'
            },
            {
              path: testpath,
              permission: 'search'
            }
          ])
      } else {
        element.visibility =
          !element.hideNavigation &&
          privileges.has({
            path: element.route,
            permission: 'read'
          })
      }
    }
    somethingVisible = somethingVisible || element.visibility
  })
  return somethingVisible
}

const privilegesStore = usePrivilegesStore()

const reloadNav = () => {
  console.log('got a reloadnav')
  console.log('privilegesStore.privileges.length', privilegesStore.privileges.length)
  setNavigationVisibility(navTreeData.value)
}

const backToOriginDomain = async () => {
  await personsService.backToOriginDomain()
}

const backToOriginUser = async () => {
  const legalEntityStore = useLegalEntityStore()
  legalEntityStore.setSelectedApplicationUser(0)
  await backToOriginDomain()
}

const getDomainText = (item) => ` ${item.name1 || ''} ${item.name2 || ''} `

const gotoLegacyNav = (route) => {
  gotoLegacy(route.split('/')[2], route.split('/')[3])
}
</script>
<style lang="scss" scoped>
@import 'bootstrap/dist/css/bootstrap.min.css';

.dropdown-menu {
  width: 100%;
  background: #f1f1f1;
}

.emailTopMargin {
  margin-top: 6px;
}
</style>
