import { isValidString } from '@/utils/TypeGuards'
import { hasLowerCasePattern, ibanPattern, multipleWhiteSpacePattern } from '@/utils/regexPatterns'

/**
 * Check if given iban matches {@link ibanPattern}.
 *
 * @remarks Will also check for multiple whitespaces, lowercase characters and multiline.
 * @remarks Will also perform checksum validations.
 * @remarks This is a port of the Java method {@link https://github.com/manuelbl/SwissQRBill/blob/c2dd1b8d31575e9f3a62144d8dc131f8b23a7c92/generator/src/main/java/net/codecrete/qrbill/generator/Payments.java#L98} which is used in the backend.
 */
export function isValidIban(iban: unknown): iban is string {
  if (isValidString(iban)) {
    if (multipleWhiteSpacePattern.test(iban)) {
      // we allow IBANs to have single white space (to make it easier for user to read), but not multiple
      return false
    }

    if (iban.includes('\n') || iban.includes('\r')) {
      // IBANs cant have new lines
      return false
    }

    if (hasLowerCasePattern.test(iban)) {
      // Technically speaking, IBANs are case-insensitive, but we want to make sure that the user enters it in uppercase
      return false
    }

    const checkDigits = iban.substring(2, 4)
    if (['00', '01', '99'].includes(checkDigits)) {
      return false
    }

    // remove white spaces before doing big boy checks
    const ibanWithoutSpaces = iban.replaceAll(' ', '')

    // check if its international iban and check if it has valid mod97 check digits
    return ibanPattern.test(ibanWithoutSpaces) && hasValidMod97CheckDigits(ibanWithoutSpaces)
  }
  return false
}

/**
 * Indicates if the string is a valid QR-IBAN.
 * <p>
 * QR-IBANs are IBANs with an institution ID in the range 30000 to 31999
 * and a country code for Switzerland or Liechtenstein.
 * Thus, they must have the format "CH..30...", "CH..31...", "LI..30..." or "LI..31...".
 * </p>
 *
 * @param iban account number to check
 * @return {@code true} for valid QR-IBANs, {@code false} otherwise
 * @remarks This function is a port of {@link https://github.com/manuelbl/SwissQRBill/blob/c2dd1b8d31575e9f3a62144d8dc131f8b23a7c92/generator/src/main/java/net/codecrete/qrbill/generator/Payments.java#L135}
 */
export function isQRIBAN(iban: string): boolean {
  return isValidIban(iban) && (iban.startsWith('CH') || iban.startsWith('LI')) && iban.charAt(4) === '3' && (iban.charAt(5) === '0' || iban.charAt(5) === '1')
}

/**
 * Checks if check digits of IBAN is valid.
 * @remarks Port of {@link https://github.com/manuelbl/SwissQRBill/blob/c2dd1b8d31575e9f3a62144d8dc131f8b23a7c92/generator/src/main/java/net/codecrete/qrbill/generator/Payments.java#L216}
 */
function hasValidMod97CheckDigits(iban: string): boolean {
  return calculateMod97(iban) === 1
}

/**
 * Calculate the reference's modulo 97 checksum according to ISO11649 and IBAN
 * standard.
 * <p>
 * The string may only contains digits, letters ('A' to 'Z' and 'a' to 'z', no
 * accents). It must not contain white space.
 * </p>
 *
 * @param reference the reference
 * @return the checksum (0 to 96)
 * @throws IllegalArgumentException thrown if the reference contains an invalid character
 * @remarks Port of {@link https://github.com/manuelbl/SwissQRBill/blob/c2dd1b8d31575e9f3a62144d8dc131f8b23a7c92/generator/src/main/java/net/codecrete/qrbill/generator/Payments.java#L233}
 */
export function calculateMod97(reference: string): number {
  const len = reference.length
  if (len < 5) {
    throw new Error('Insufficient characters for checksum calculation')
  }

  const rearranged = reference.substring(4) + reference.substring(0, 4)
  let sum = 0
  for (let i = 0; i < len; i++) {
    const ch = rearranged.charAt(i)
    if (ch >= '0' && ch <= '9') {
      sum = sum * 10 + (ch.charCodeAt(0) - '0'.charCodeAt(0))
    } else if (ch >= 'A' && ch <= 'Z') {
      sum = sum * 100 + (ch.charCodeAt(0) - 'A'.charCodeAt(0) + 10)
    } else if (ch >= 'a' && ch <= 'z') {
      sum = sum * 100 + (ch.charCodeAt(0) - 'a'.charCodeAt(0) + 10)
    } else {
      throw new Error('Invalid character in reference: ' + ch)
    }
    if (sum > 9999999) {
      sum = sum % 97
    }
  }

  sum = sum % 97
  return sum
}
