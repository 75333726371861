<template>
  <v-container class="bradcrumb-padding d-print-none">
    <div class="breadbrumbs">
      <span v-if="typeof selectedNav1 != 'undefined'">
        <v-icon dbmpassivgrey size="12px">mdi-home</v-icon>
        {{ selectedNav1.label[selectedLang.lang] }}
      </span>

      <span v-if="typeof selectedNav2 != 'undefined'"
        ><v-icon dbmpassivgrey size="12px">mdi-arrow-right</v-icon>
        {{ selectedNav2.label[selectedLang.lang] }}
      </span>

      <span v-if="typeof selectedNav3 != 'undefined'">
        <v-icon dbmpassivgrey size="12px">mdi-arrow-right</v-icon>
        {{ selectedNav3.label[selectedLang.lang] }}
      </span>
    </div>
  </v-container>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { navTree, NavTreeElement } from '@/router/navTree'
import { RouteLocationNormalizedLoadedGeneric, useRoute } from 'vue-router'
import { selectedLang } from '@/services/language'

const route = useRoute()

const navTreeData = ref(navTree as NavTreeElement[])

const selectedNav1 = computed(() => {
  return navTreeData.value.filter((elem: NavTreeElement) => elem.selectedPath)[0]
})

const selectedNav2 = computed(() => {
  if (!selectedNav1.value) return
  return selectedNav1.value.children.filter((elem: NavTreeElement) => elem.selectedPath)[0]
})

const selectedNav3 = computed(() => {
  if (!selectedNav2.value) return
  return selectedNav2.value.children.filter((elem: NavTreeElement) => elem.selectedPath)[0]
})

watch(route, (to) => {
  setRouteInfo(to)
})
const setRouteInfo = (routeInfo: RouteLocationNormalizedLoadedGeneric) => {
  setFocusElements(navTreeData.value, routeInfo.path && routeInfo.path.length ? routeInfo.path : '#nope')
}
const setFocusElements = (arrayOfRouteElements: NavTreeElement[], focusPath: string) => {
  let anyFocus = false
  arrayOfRouteElements.forEach((element) => {
    if (focusPath.replace(/\/\d+/, '/read').search(element.route) > -1) {
      element.selectedPath = true
      anyFocus = true
    } else {
      if (element.children && element.children.length > 0) {
        element.selectedPath = setFocusElements(element.children, focusPath)
        anyFocus = anyFocus || element.selectedPath
      } else {
        element.selectedPath = false
      }
    }
  })
  return anyFocus
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.bradcrumb-padding {
  padding-top: $spacer-xs !important;
  padding-bottom: $spacer-xxxs !important;
}

.breadbrumbs {
  font-size: $font-xs;
}

.material-icons {
  vertical-align: middle !important;
}
</style>
