import { getLocale } from '@/utils/LocaleUtils'
import { getLogger, Logger } from '@/utils/Logger'
import { isNaturalSafeInteger, isNullOrUndefined, isSafeInteger, isValidDate, isValidISODate, isValidString } from '@/utils/TypeGuards'
import { i18n } from '@/i18n'
import { format, parseISO } from 'date-fns'
import { Temporal } from '@js-temporal/polyfill'
import PlainDateTime = Temporal.PlainDateTime
import PlainDate = Temporal.PlainDate

const logger: Logger = getLogger('TimeUtils')

/**
 * Returns `true` if the `targetDate` is between the `validFrom` and `validUntil` dates.
 * @notes Dates that are `null` or `undefined` are considered forever valid.
 */
export function isValid(inValidFrom?: SomeDate, inValidUntil?: SomeDate, inTargetDate: Date = new Date()): boolean {
  const validFrom = (isNullOrUndefined(inValidFrom) ? new Date(DATE_LIMIT.MIN_VALUE) : new Date(inValidFrom)).getTime()
  const validUntil = (isNullOrUndefined(inValidUntil) ? new Date(DATE_LIMIT.MAX_VALUE) : new Date(inValidUntil)).getTime()
  const targetDate = inTargetDate.getTime()

  if (isSafeInteger(validFrom) && isSafeInteger(validUntil) && isSafeInteger(targetDate)) {
    const isBefore: boolean = validFrom < targetDate
    const isAfter: boolean = validUntil > targetDate
    return isBefore && isAfter
  }
  const error = new Error('isValid: Cannot determine if time range is valid')
  logger.error(error, { validFrom, validUntil })
  throw error
}

/**
 * Formats the given date according to the locale and options.
 * @returns Formatted date string e.g. `31.12.2024`
 */
export function formatDate(date: Date, locale?: Intl.Locale, options?: Intl.DateTimeFormatOptions): string {
  return new Intl.DateTimeFormat(locale ?? getLocale(), options).format(date)
}

/**
 * Returns period of years.
 *
 * @param startingYear Starting year.
 * @param startingMonth Starting month index (0 = January).
 * @param durationYears Duration in years.
 * @returns Array with two dates: start and end of the period.
 */
export function toPeriodOfYears(startingYear: number, startingMonth: number, durationYears: number): Date[] {
  const startOfLastSeason: Date = new Date(startingYear, startingMonth, 1)
  const endOfLastSeason: Date = new Date(startingYear + durationYears, startingMonth, 0) // 0 = last day of previous month
  return [startOfLastSeason, endOfLastSeason]
}

/**
 * Formats the given ISO date to a (fully) human-readable date.
 * Hint. Unlike {@link formatDate} this function also supports replacements {@link FormatDateHumanReadableOptions} for empty or invalid dates.
 *       e.g. `31.12.2024` or `forever` or `invalid date`
 * @see formatDate
 * @param options {@link FormatDateHumanReadableOptions}
 * @param removeDay If true, the day will be removed from the formatted date.
 * @returns Human readable date string e.g. `31.12.2024`
 */
export function formatDateHumanReadable(options: FormatDateHumanReadableOptions, removeDay: boolean = true): string {
  const isoDate = options.isoDate
  if (isValidString(isoDate)) {
    const date: Date = parseISO(isoDate)
    if (isValidDate(date)) {
      const options: Intl.DateTimeFormatOptions = {
        day: removeDay ? undefined : '2-digit',
        month: removeDay ? 'long' : '2-digit',
        year: 'numeric'
      }
      return formatDate(date, undefined, options)
    }
    logger.anomaly('isoDate is not a valid date', isoDate)
    if (isValidString(options.replacement?.invalid)) {
      return i18n.global.t(options.replacement.invalid)
    }

    return isoDate
  }
  if (isValidString(options.replacement?.empty)) {
    return i18n.global.t(options.replacement.empty)
  }
  logger.warn(`isoDate is not a valid string (${isoDate}) and no replacement when empty is set`)
  return i18n.global.t('error')
}

/**
 * DbmMonthPicker doesn't return the same isoDate format as it receives (strips away up to date only e.g. '2024-12-31') but the backend expects a isoDateTime with timezone (ZoneDateTime).
 * <p> This method will convert the dates to Instants {@link IsoDateType}.
 * <p> Hint. Will only normalize the validUntil, validFrom if they are set (will ignore `null` and `undefined` values).
 *
 * @param obj The object to normalize the validUntil, validFrom.
 * @deprecated Use {@link Temporal} and/or {@link DbmDateInput} instead.
 */
export function normalizeTimeRanged<T extends TimeRange>(obj: T): void {
  logger.warn('normalizeTimeRanged() is deprecated. Use Temporals and/or DbmDateInput instead')
  if (!isNullOrUndefined(obj.validFrom)) {
    if (!isValidISODate(obj.validFrom)) {
      throw logger.asError(`Invalid ISO date in validFrom found: ${obj.validFrom}`, obj)
    }
    obj.validFrom = asInstantIso(obj.validFrom)
  }
  if (!isNullOrUndefined(obj.validUntil)) {
    if (!isValidISODate(obj.validUntil)) {
      throw logger.asError(`Invalid ISO date in validUntil found: ${obj.validUntil}`, obj)
    }
    obj.validUntil = asInstantIso(obj.validUntil)
  }
}

/**
 * Convert any isoDate ({@link IsoDateType}) to an instant isoDate (containing time and timeZone).
 *
 * @param isoDate The date to convert.
 * @deprecated Use {@link Temporal} and/or {@link DbmDateInput} instead.
 */
function asInstantIso(isoDate: string): string {
  logger.warn('asInstantIso() is deprecated. Use Temporal and/or DbmDateInput instead')
  if (!isValidISODate(isoDate)) {
    throw logger.asError(`Invalid ISO date: ${isoDate}`)
  }
  const isoDateType: IsoDateType = getIsoDateType(isoDate)
  if (isoDateType === 'Instant') {
    // no need to convert
    return isoDate
  }
  if (isoDateType === 'PlainDateTime') {
    // add timezone
    logger.warn(`Converting PlainDateTime to Instant using default timezone (${defaultTimeZoneId}). This is deprecated. Use DbmDateInput instead`)
    return PlainDateTime.from(isoDate).toZonedDateTime(defaultTimeZoneId).toString()
  }
  if (isoDateType === 'PlainDate') {
    // add time and timezone
    logger.warn(`Converting PlainDate to Instant using default timezone (${defaultTimeZoneId}). This is deprecated. Use DbmDateInput instead`)
    return PlainDate.from(isoDate).toZonedDateTime(defaultTimeZoneId).toString()
  }

  throw logger.asError(`Invalid ISO date type or not implemented yet: ${isoDateType}`)
}

/**
 * Returns true if the given time range is valid.
 * Hint. If start is forever and end is forever, the range is valid.
 *
 * @param start {@link SomeDate}
 * @param end {@link SomeDate}
 * @returns True if the time range is valid.
 */
export function timeRangeIsValid(start: SomeDate, end: SomeDate): boolean {
  const validFrom: Date = isNullOrUndefined(start) ? new Date(DATE_LIMIT.MIN_VALUE) : new Date(start)
  const validUntil: Date = isNullOrUndefined(end) ? new Date(DATE_LIMIT.MAX_VALUE) : new Date(end)

  return validFrom.getTime() < validUntil.getTime()
}

/**
 * Returns true if the given array of {@link TimeRange}s has overlaps.
 * @notes Dates are converted to {@link TimeRangeStrict} to avoid side effects.
 * @notes Dates that are `null` or `undefined` are considered forever valid.
 */
export function hasOverlaps(timeRanges: TimeRange[]): boolean {
  // convert to real date objects
  const dates: TimeRangeStrict[] = convertToStrictDates(timeRanges)
    // Sort the dates by validFrom date
    .sort((a, b) => a.validFrom.getTime() - b.validFrom.getTime())

  for (let i = 0; i < dates.length - 1; i++) {
    const current: TimeRangeStrict = dates[i]
    const next: TimeRangeStrict = dates[i + 1]

    // Check if the current date's validUntil is after the next date's validFrom
    if (current.validUntil > next.validFrom) {
      return true // Overlap found
    }
  }
  return false // No overlaps found
}

/**
 * Converts an array of {@link TimeRange}s to an array of {@link TimeRangeStrict}s.
 * @notes Dates that are `null` or `undefined` are considered forever valid.
 * @notes Forever valid dates are represented as {@link DATE_LIMIT.MIN_VALUE} and {@link DATE_LIMIT.MAX_VALUE}.
 * @see DATE_LIMIT
 */
export function convertToStrictDates(timeRanges: TimeRange[]): TimeRangeStrict[] {
  return timeRanges.map((range: TimeRange) => {
    // make a copies of the reference to avoid modifications (side effects)
    const validFrom = isNullOrUndefined(range.validFrom) ? new Date(DATE_LIMIT.MIN_VALUE) : new Date(range.validFrom)
    const validUntil = isNullOrUndefined(range.validUntil) ? new Date(DATE_LIMIT.MAX_VALUE) : new Date(range.validUntil)

    if (isSafeInteger(validFrom.getTime()) && isSafeInteger(validUntil.getTime())) {
      return {
        validFrom,
        validUntil
      }
    }
    const error = new Error('convertToStrictDates: Cannot determine if time range is valid')
    logger.error(error, { validFrom, validUntil })
    throw error
  })
}

/**
 * The maximum timestamp representable by a Date object is slightly smaller than the
 * maximum safe integer ({@link Number.MAX_SAFE_INTEGER}, which is `9,007,199,254,740,991`).
 * A Date object can represent a maximum of `±8,640,000,000,000,000` milliseconds,
 * or `±100,000,000` (one hundred million) days, relative to the epoch.
 * This is the range from April 20, 271821 BC to September 13, 275760 AD.
 *
 * Any attempt to represent a time outside this [hardware limited] range results in the
 * Date object holding a timestamp value of `NaN`, which is an `Invalid Date`.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
 * @readonly
 * @notes We use them because `validFrom=null|undefined` equals `forever` and `validUntil=null|undefined` equals `forever` but we can't use those values (`null|undefined`) to do mathematically operations (e.g. bigger than).
 */
export const DATE_LIMIT: Readonly<{
  /** The minimum date representable by a Date object, equals `Tue Apr 20 -271821 00:34:08 GMT+0034 (Central European Summer Time)` */
  MIN_VALUE: Readonly<number>
  //MIN: Readonly<Date>
  /** The maximum date representable by a Date object, equals `Sat Sep 13 275760 02:00:00 GMT+0200 (Central European Summer Time)` */
  MAX_VALUE: Readonly<number>
  //MAX: Readonly<Date>
}> = {
  //MIN: new Date(-8640000000000000),
  MIN_VALUE: -8640000000000000,
  //MAX: new Date(8640000000000000),
  MAX_VALUE: 8640000000000000
}

/**
 * @deprecated Use {@link formatDateHumanReadable} or {@link formatDate} instead.
 */
export function formatDateTime(date: string) {
  if (!date) return null

  const test = /^\d{4}-\d{2}-\d{2}$/
  if (test.test(date)) return date + 'T00:00:00'

  throw new Error('Invalid date format')
}

/**
 * @deprecated Use {@link formatDateHumanReadable} or {@link formatDate} instead.
 */
export function formatChDateTime(dateTime: string | undefined | null) {
  if (!dateTime || dateTime.length < 10) return ''

  return format(parseISO(dateTime), 'dd.MM.yyyy HH:mm')
}

/**
 * @deprecated Use {@link formatDateHumanReadable} or {@link formatDate} instead.
 */
export function formatChDate(date: string | undefined | null): string {
  if (typeof date !== 'string') return ''

  const [year, month, day] = date.split('-')
  return `${day}.${month}.${year}`
}

/**
 * Returns the next month.
 *
 * @param date Date to start from.
 * @param startOfMonth If true, the date will be set to the first day of the next month, otherwise to the last day of the next month.
 * @returns Date of the next month.
 */
export function toNextMonth(date: SomeDate, startOfMonth: boolean): Date {
  if (isNullOrUndefined(date)) {
    throw new Error('toNextMonth: date is null or undefined')
  }
  const nextMonth: Date = new Date(date)
  if (startOfMonth) {
    nextMonth.setMonth(nextMonth.getMonth() + 1)
    nextMonth.setDate(1)
  } else {
    // + 2 months because setDate(0) will go one day back from current month
    nextMonth.setMonth(nextMonth.getMonth() + 2)
    nextMonth.setDate(0)
  }

  return nextMonth
}

/**
 * Will return date only (without time) in the format `YYYY-MM-DD`.
 * @remarks Use {@link Date#toISOString} to get the full date-time string.
 */
export function toIsoDate(date: Date): string {
  return date.toISOString().split('T')[0]
}

/** converts a period eg. 202208 which stands for the August 2022 into a Iso String '2022-08-01' */
export function periodToISO(period: number): string {
  const year = Math.floor(period / 100)
  const month = period % 100
  return `${year.toString()}-${month.toString().padStart(2, '0')}-01`
}
export function isoToPeriod(isoDate: string): number {
  return parseInt(format(parseISO(isoDate), 'yyyyMM'))
}

/**
 * Converts {@link Intl.DateTimeFormatOptions} to a string eg. 'MM.YYYY'
 */
function getDateFormat(locale?: Intl.Locale, options?: Intl.DateTimeFormatOptions): string {
  const formatted = formatDate(new Date('1977-01-02T03:04:05.99'), locale, options)
  return formatted
    .replace('1977', 'YYYY')
    .replace('77', 'YY')
    .replace('01', 'MM')
    .replace('1', 'M')
    .replace('02', 'DD')
    .replace('2', 'D')
    .replace('03', 'HH')
    .replace('3', 'H')
    .replace('04', 'mm')
    .replace('4', 'm')
    .replace('05', 'ss')
    .replace('5', 's')
}

/**
 * Generates a regex to parse a date string in the given format.
 * @param format Date format string e.g. 'DD.MM.YYYY'
 */
function getFormattedDateRegex(format: string): RegExp {
  return new RegExp('^\\s*' + format.replaceAll(/([MDYHms])\1*/g, '(?<$1>\\d+)') + '\\s*$')
}

/**
 * Parses a formatted date string to a Date object.
 * Can parse dates in the format 'DD.MM.YYYY', 'D.M.YYYY', 'DD.MM.YY', 'D.M.YY', etc.
 * @see formatDate
 * @remarks inspired by {@link https://stackoverflow.com/a/76775835}
 */
export function parseFormattedDate(
  value: string,
  locale?: Intl.Locale,
  options?: Intl.DateTimeFormatOptions
):
  | {
      month: number | undefined
      year: number | undefined
      day: number | undefined
      hour: number | undefined
      minute: number | undefined
      second: number | undefined
    }
  | undefined {
  const format = getDateFormat(locale, options)

  const regex = getFormattedDateRegex(format)
  const { groups } = value.match(regex) ?? {}

  if (!groups) return undefined
  const year = Number(groups.Y)
  const month = Number(groups.M)
  const day = Number(groups.D)
  const hour = Number(groups.H)
  const minute = Number(groups.m)
  const second = Number(groups.s)

  return {
    year: isValidString(groups.Y) ? year : undefined,
    month: isNaturalSafeInteger(month) ? month : undefined,
    day: isNaturalSafeInteger(day) ? day : undefined,
    hour: isNaturalSafeInteger(hour) ? hour : undefined,
    minute: isNaturalSafeInteger(minute) ? minute : undefined,
    second: isNaturalSafeInteger(second) ? second : undefined
  }
}

/**
 * Regex for isoDate with timezone.
 * @example `2022-08-01T00:00:00+02:00`, the `+02:00` is the timezone.
 */
export const isoDateTimezoneRegex: RegExp = /([+-]\d{2}:\d{2}|Z)$/

/**
 * Check if the given isoDate has a timezone.
 *
 * @param isoDate - The date to check
 * @returns `true` if the date has a timezone (e.g. `2022-08-01T00:00:00+02:00`), `false` otherwise
 */
export function hasTimezone(isoDate: string): boolean {
  return (
    isValidISODate(isoDate) &&
    // isoDate cant have timezone without time
    isoDate.includes('T') &&
    isoDateTimezoneRegex.test(isoDate)
  )
}

/**
 * Type of ISO date.
 *
 * - `PlainDate` (Java equivalent of `LocalDate`) - Date without time or timezone (e.g. `2022-08-01`)
 * - `PlainDateTime` (Java equivalent of  `LocalDateTime`) - Date with time but without timezone (e.g. `2022-08-01T00:00:00`)
 * - `Instant` (Java equivalent of  `ZonedDateTime`= - Date with time and timezone (e.g. `2022-08-01T00:00:00+02:00`)
 */
export type IsoDateType = 'PlainDate' | 'PlainDateTime' | 'Instant'

/**
 * Backend stores date in a default `timeZoneId` and the frontend has to use the same to avoid time zone issues.
 * @remarks A {@link TimeZoneLike} can contain a timeZoneId which is represented as `string`
 */
export const defaultTimeZoneId: string = 'Europe/Zurich'

/**
 * Determine the type ({@link IsoDateType}) of the given ISO date.
 * @param isoDate
 */
export function getIsoDateType(isoDate: string): IsoDateType {
  if (!isValidISODate(isoDate)) {
    throw new Error('Invalid ISO date')
  }
  if (isoDate.includes('T')) {
    return hasTimezone(isoDate) ? 'Instant' : 'PlainDateTime'
  }
  return 'PlainDate'
}

/**
 * Compare two dates. Useful for sorting.
 *
 * @param a - The first date
 * @param b - The second date
 * @param replacement - The replacement if the date is `null` or `undefined`
 * @remarks replacement is used to implement `undefined`|`null` as `forever`
 * @remarks e.g. `validFrom` should use `MIN_VALUE` and `validUntil` should use `MAX_VALUE`
 * @returns Difference of the two dates compared.
 */
export function compareDates(a: SomeDate, b: SomeDate, replacement: keyof typeof DATE_LIMIT): number {
  const aTimestamp: number = new Date(a ?? DATE_LIMIT[replacement]).getTime()
  const bTimestamp: number = new Date(b ?? DATE_LIMIT[replacement]).getTime()

  return aTimestamp - bTimestamp
}

/** Represents a date that can be a string (isoDate), number (utc time), Date, forever (=null or undefined)*/
export type SomeDate = string | Date | undefined | null | number

interface FormatDateHumanReadableOptions {
  /** ISO-Date to format */
  isoDate?: string
  /** Replacement when date matches given criteria, if undefined original {@link isoDate} will be returned instead  */
  replacement?: {
    /** Replacement when date is invalid e.g. 'invalid date'/'Ungültiges Datum' */
    invalid?: string
    /** Replacement when date is empty e.g. 'forever'/'Unbegrenzt'*/
    empty?: string
  }
}

/** Represents a subset of an object that contains validity dates (`validFrom`, `validUntil`) */
export interface TimeRange {
  validFrom?: SomeDate
  validUntil?: SomeDate
}

/** Represents a subset of an object that contains validity dates (`validFrom`, `validUntil`) */
interface TimeRangeStrict {
  validFrom: Date
  validUntil: Date
}
